import request from '@/utils/request'

interface IngredientTranslation {
  language_id: number
  translation: string
}
interface Ingredient {
  id: number
  name: IngredientTranslation[]
  image?: string
}
export interface IngredientEntry {
  ingredient: Ingredient
  amount: number
  amount_suffix: string
}

interface DescriptionTranslation {
  translation: string
  language_id: number
}
export interface DescriptionStep {
  description: DescriptionTranslation[]
}

export interface Upload {
  required_time: number
  image?: any
  meat: boolean
  vegetarian: boolean
  vegan: boolean
  portions: number
  ingredients: IngredientEntry[]
  descriptionSteps: DescriptionStep[]
}

export const create = (payload: any) =>
  request({
    url: '/recipe',
    method: 'post',
    data: payload
  })
export const getList = () =>
  request({
    url: '/recipe/list',
    method: 'get'
  })
export const getTagList = () =>
  request({
    url: `/recipe/tags`,
    method: 'get'
  })

export const get = (id: string) =>
  request({
    url: `/recipe/${id}`,
    method: 'get'
  })
