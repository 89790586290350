import request from '@/utils/request'

export const login = (username: any, password: any) =>
  request({
    url: '/auth/user/login',
    method: 'post',
    data: { email: username, password }
  })
export const logout = () =>
  request({
    url: '/auth/user/logout',
    method: 'get'
  })
export const ping = () =>
  request({
    url: '/auth/user/ping',
    method: 'get'
  })
