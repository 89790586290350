


































import { Component, Vue } from 'vue-property-decorator'
import NavBar from '@/components/NavBar.vue'
import MobileNavDrawer from '@/components/mobileNavDrawer.vue'

const faviconPath = './favicon.ico'

@Component({
  name: 'App',
  metaInfo: {
    title: 'supertolle Essensseite, viel Spaß beim kochen!',
    titleTemplate: '%s | food.chirmi.dev',
    link: [{ rel: 'icon', href: faviconPath }]
  },
  components: { NavBar, MobileNavDrawer }
})
export default class extends Vue {
  links: Array<string> = ['Dashboard', 'Messages', 'Profile', 'Updates']
  created() {
    this.$vuetify.theme.dark = true
    this.$store.dispatch('loginCookie')
    this.$store.dispatch('refreshTags')
  }
}
