






















































































import { Component, Vue } from 'vue-property-decorator'
import * as RecipeAPI from '@/api/recipe'
import FoodCard from '@/components/foodCard.vue'
@Component({
  name: 'Home',
  components: { FoodCard }
})
export default class extends Vue {
  foodPreferences = 0
  recipes = []

  getTime(timeId: number) {
    if (timeId < 60) {
      return timeId.toString() + ' Min'
    } else if (timeId < 24 * 60) {
      return Math.round(timeId / 60) + ' Std'
    } else if (timeId < 24 * 60 * 14) {
      return Math.round(timeId / 60 / 24) + ' Tage'
    } else {
      return 'Lange'
    }
  }
  async created() {
    const list = await RecipeAPI.getList()
    if (list.data != null && list.data.length > 0) {
      console.log(list.data)
      this.recipes = list.data
    }
  }
  imageSource = 'https://picsum.photos/1920/1080?random'
}
