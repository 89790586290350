












































































import { Component, Vue } from 'vue-property-decorator'
import Login from '@/components/Login.vue'
@Component({
  name: 'MobileNavDrawer',
  components: { Login }
})
export default class extends Vue {
  dialog = false
  logout() {
    this.dialog = false
    this.$store.dispatch('logout')
  }
}
