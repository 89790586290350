





























import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({
  name: 'foodCard',
  components: {}
})
export default class extends Vue {
  @Prop({ required: true }) id: string
  @Prop({ required: true }) title: string
  @Prop({}) image: string
  @Prop({ required: true }) time: string
  @Prop({ default: false }) meat: boolean
  @Prop({ default: false }) vegetarian: boolean
  @Prop({ default: false }) vegan: boolean
}
