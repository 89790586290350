var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.$route.path == '/')?_c('v-app-bar',{attrs:{"color":"#fcb69f","app":"","dark":"","src":require("@/assets/main.jpg")},scopedSlots:_vm._u([{key:"img",fn:function(ref){
var props = ref.props;
return [_c('v-img',_vm._b({attrs:{"gradient":"to top right, rgba(19,84,122,.3), rgba(128,208,199,.7)"}},'v-img',props,false))]}}],null,false,427267063)},[(_vm.$vuetify.breakpoint.xsOnly)?_c('v-app-bar-nav-icon',{on:{"click":function($event){_vm.$store.state.drawer = !_vm.$store.state.drawer}}}):_vm._e(),_c('v-toolbar-title',{staticClass:"d-none d-md-flex"},[_c('v-btn',{attrs:{"link":"","to":"/","text":"","tile":"","elevation":0}},[_vm._v("Zur Startseite")])],1),_c('v-spacer'),_c('v-responsive',{attrs:{"max-width":"400"}},[_c('v-text-field',{staticClass:"search",attrs:{"dense":"","flat":"","hide-details":"","rounded":"","solo-inverted":"","label":"Suche","append-icon":"mdi-magnify"}})],1),_c('v-spacer'),(!_vm.$store.state.authenticated)?_c('v-dialog',{attrs:{"width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-account")])],1)]}}],null,false,4230390063),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('login')],1):_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-account")])],1)]}}],null,false,4230390063)},[_c('v-list',[_c('v-list-item',[_c('v-list-item-title',{on:{"click":function($event){return _vm.logout()}}},[_vm._v("Abmelden")])],1)],1)],1),_c('v-btn',{staticClass:"d-none d-md-flex",attrs:{"icon":""},on:{"click":function($event){return _vm.switchTheme()}}},[_c('v-icon',[_vm._v("mdi-brightness-6")])],1),_c('v-btn',{staticClass:"d-none d-md-flex",attrs:{"icon":""}},[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)],1):_c('v-app-bar',{attrs:{"color":"#fcb69f","app":"","dark":"","src":require("@/assets/main.jpg")},scopedSlots:_vm._u([{key:"img",fn:function(ref){
var props = ref.props;
return [_c('v-img',_vm._b({attrs:{"gradient":"to top right, rgba(19,84,122,.3), rgba(128,208,199,.7)"}},'v-img',props,false))]}}])},[_c('v-app-bar-nav-icon'),_c('v-toolbar-title',[_c('v-btn',{attrs:{"link":"","to":"/","text":"","tile":"","elevation":0}},[_vm._v("Aura Food")])],1),_c('v-spacer'),_c('v-responsive',{attrs:{"max-width":"400"}},[_c('v-text-field',{staticClass:"search",attrs:{"dense":"","flat":"","hide-details":"","rounded":"","solo-inverted":"","label":"Suche","append-icon":"mdi-magnify"}})],1),_c('v-spacer'),_c('div',{staticClass:"d-none d-md-flex"},[(!_vm.$store.state.authenticated)?_c('v-dialog',{staticClass:"d-none d-md-flex",attrs:{"width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-account")])],1)]}}],null,false,4230390063),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('login')],1):_c('v-menu',{staticClass:"d-none d-md-flex",attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-account")])],1)]}}])},[_c('v-list',[_c('v-list-item',[_c('v-list-item-title',{on:{"click":function($event){return _vm.logout()}}},[_vm._v("Abmelden")])],1)],1)],1)],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.switchTheme()}}},[_c('v-icon',[_vm._v("mdi-brightness-6")])],1),_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }