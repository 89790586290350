import axios from 'axios'
// import { UserModule } from '@/store/modules/user'

//console.log(process.env.VUE_APP_BASE_API)
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  timeout: 10000,
  withCredentials: true // send cookies when cross-domain requests
})

// Request interceptors
service.interceptors.request.use(
  config => {
    // Add X-Access-Token header to every request, you can add other custom headers here
    // if (UserModule.token) {
    // const token = localStorage.getItem('token')
    // if (token != null) {
    //   config.headers['X-Access-Token'] = token
    // }
    //UserModule.token
    // }
    return config
  },
  error => {
    Promise.reject(error)
  }
)

// Response interceptors
service.interceptors.response.use(
  response => {
    // Some example codes here:
    // code == 20000: success
    // code == 50001: invalid access token
    // code == 50002: already login in other place
    // code == 50003: access token expired
    // code == 50004: invalid user (user not exist)
    // code == 50005: username or password is incorrect
    // You can change this part for your own usage.
    const res = response.data
    if (res.code !== 20000) {
      console.error('Request Error: ' + (res.message ? res.message : 'no message given.'))
      // Message({
      //   message: res.message || 'Error',
      //   type: 'error',
      //   duration: 5 * 1000
      // })
      if (res.code === 50008 || res.code === 50012 || res.code === 50014) {
        // MessageBox.confirm(
        //   'Sie wurden abgemeldet, können abbrechen, um auf dieser Seite zu bleiben, oder sich erneut anmelden',
        //   'Abmelden',
        //   {
        //     confirmButtonText: 'Melden Sie sich erneut an',
        //     cancelButtonText: 'Abbrechen',
        //     type: 'warning'
        //   }
        // ).then(() => {
        //   UserModule.ResetToken()
        //   location.reload() // To prevent bugs from vue-router
        // })
      }
      return Promise.reject(new Error(res.message || 'Error'))
    } else {
      return response.data
    }
  },
  error => {
    console.log('Request Error: ' + error.message)
    // Message({
    //   message: error.message,
    //   type: 'error',
    //   duration: 5 * 1000
    // })
    return Promise.reject(error)
  }
)

export default service
