































import { Component, Vue } from 'vue-property-decorator'
@Component({
  name: 'Login',
  components: {}
})
export default class extends Vue {
  email = ''
  password = ''
  login() {
    this.$store.dispatch('login', { email: this.email, password: this.password })
  }
}
