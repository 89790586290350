
















































































































import { Component, Vue } from 'vue-property-decorator'
import Login from '@/components/Login.vue'
import MobileNavDrawer from './mobileNavDrawer.vue'
@Component({
  name: 'NavBar',
  components: { Login, MobileNavDrawer }
})
export default class extends Vue {
  dialog = false
  logout() {
    this.dialog = false
    this.$store.dispatch('logout')
  }
  switchTheme() {
    this.$vuetify.theme.dark = !this.$vuetify.theme.dark
  }
}
