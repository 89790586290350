import router from '@/router'
import Vue from 'vue'
import Vuex from 'vuex'
import * as UserAPI from '@/api/user'
import * as RecipeAPI from '@/api/recipe'

Vue.use(Vuex)

interface LoginParameters {
  email: string
  password: string
}

export default new Vuex.Store({
  state: {
    drawer: false,
    authenticated: false,
    definedTags: [],
    selectedFoodCategory: 0,
    foodCategories: [
      { text: 'Alle', icon: 'mdi-view-dashboard-variant' },
      { text: 'Frühstück', icon: 'mdi-food-croissant' },
      { text: 'Mittagessen', icon: 'mdi-noodles' },
      { text: 'Abendessen', icon: 'mdi-baguette' },
      { text: 'Snacks', icon: 'mdi-peanut' }
    ],
    ticksLabels: ['30m', '1h', '3h', '∞'],
    timeSlider: 3
  },
  mutations: {
    setAuthenticated(state, value) {
      state.authenticated = value
    },
    setTags(state, tags) {
      state.definedTags = tags
    }
  },
  actions: {
    async login(state, credentials: LoginParameters) {
      const response = await UserAPI.login(credentials.email, credentials.password)
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      if (response.data.successful) {
        state.commit('setAuthenticated', true)
        // const token = response.data.accessToken
        // localStorage.setItem('token', token)
        // state.commit('setToken', token)
        // console.log(state.state.token)
        // console.log(state.getters.authorized)
      } else {
        state.commit('setAuthenticated', false)
        throw Error(response.data.error)
      }
    },
    async loginCookie(state) {
      const response = await UserAPI.ping()
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      if (response.data.isAuthenticated) {
        state.commit('setAuthenticated', true)
      } else {
        state.commit('setAuthenticated', false)
      }
    },
    async refreshTags(state) {
      const response = await RecipeAPI.getTagList()
      if (response.data) {
        state.commit('setTags', response.data)
      }
    },
    logout(state) {
      state.commit('setAuthenticated', false)
      document.cookie = 'connect.sid=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
      UserAPI.logout()
    }
  },
  modules: {}
})
